interface HomePageProps {
    isHomeVisible: boolean;
    isSkillsVisible: boolean;
    isPortfolioVisible: boolean;
    isContactVisible: boolean;
}

interface SidebarProps {
    arrayButtons: string[],
    homeProps: HomePageProps,
    moveToSection: (section: string) => void,
    show: boolean
}


export function Sidebar(props: SidebarProps) {
    const {arrayButtons, homeProps, moveToSection, show} = props
    const visibilityKeys: { [key: string]: keyof typeof homeProps } = {
        Home: 'isHomeVisible',
        Skills: 'isSkillsVisible',
        Portfolio: 'isPortfolioVisible',
        Contact: 'isContactVisible'
    }

    return (
        <div className={show ? 'sidebar active' : 'sidebar'}>
            {arrayButtons.map((section) => (
                <a key={section} href="#" className={homeProps[visibilityKeys[section]] ? 'active' : ''}
                   onClick={(event) => {
                       event.preventDefault()
                       moveToSection(section)
                   }}>{section}</a>
            ))}
        </div>
    );
}