import Carousel from "react-multi-carousel";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

interface Logo{
    src: string,
    title:string
}
interface Props{
    arrayLogos: Logo[]
}

function CarouselComponent(props: Props) {
    const {arrayLogos} = props
    return (
        <Carousel responsive={responsive} infinite={true}
                  className="owl-carousel owl-theme skill-slider animate__animated animate__slideInUp">
            {arrayLogos.map((logo) =>(
                    <div
                        className="item d-flex flex-column align-items-center justify-content-center"
                        key={logo.title}>
                        <img src={logo.src} alt="Image-carousel"/>
                        <h5>{logo.title}</h5>
                    </div>
            )
            )}

        </Carousel>
    );
}

export default CarouselComponent