import "./parallax.css"
import {motion, useScroll, useTransform} from "framer-motion";
import React, {useRef} from "react";

// Define the interface for the component props
interface ParallaxProps {
    type: string; // Define the expected type for the 'type' prop
}

// Use the interface in the component
export const Parallax: React.FC<ParallaxProps> = ({ type }) => {
    const ref = useRef()
    const {scrollYProgress} = useScroll({
        target:ref,
        offset: ["start start","end start"]
    })

    const yText=useTransform(scrollYProgress,[0,1], ["0%","500%"])
    const xStars=useTransform(scrollYProgress,[0,1], ["0%","50%"])
    const yStars=useTransform(scrollYProgress,[0,1], ["0%","20%"])

    return (
        <div className="parallax"
            ref={ref}>
            <motion.h1 style={{y: yText}}>{type === "services" ? "What are my Skills?" : "What I Did?"}</motion.h1>
            <motion.div className="background"></motion.div>
            <motion.div className="clouds"></motion.div>
            <motion.div className="mountains"></motion.div>
            <motion.div style={{x: xStars, y:yStars}} className="stars"></motion.div>
        </div>
    );
};

export default Parallax