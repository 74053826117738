import {useEffect, useRef, useState} from "react";
import "./contact.css";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'

const variants = {
    initial: {
        y: 500,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            staggerChildren: 0.1,
        },
    },
};

const Contact = () => {
    const ref = useRef();
    const formRef = useRef();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        emailjs.init("LllN6y6gOs5v93Plr");
    }, []);

    useEffect(() => {
        if (success) {
            Swal.fire({
                title: "Success",
                text: "Your message has been sent",
                icon: "success",
                background: "#081b29",
                color: "#ededed",
                iconColor: "#00abf0",
                confirmButtonColor: '#00abf0',
                willClose: () => {
                    setSuccess(false);
                }
            });
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                title: "Error",
                text: "Your message has not been sent",
                icon: "error",
                background: "#081b29",
                color: "#00abf0",
                iconColor: "#00abf0",
                confirmButtonColor: '#00abf0',
                willClose: () => {
                    setError(false);
                }
            });
        }
    }, [error]);

    const sendEmail = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setLoading(true);

        emailjs
            .sendForm(
                "service_evxzdyz",
                "template_5b7rf8m",
                formRef.current,
            )
            .then(
                (result) => {
                    setSuccess(true)
                    setLoading(false);
                },
                (error) => {
                    setError(true);
                    setLoading(false);
                }
            );
    };


    return (
        <motion.div
            ref={ref}
            className="contact"
            variants={variants}
            initial="initial"
            whileInView="animate"
        >
            <motion.div className="textContainer" variants={variants}>
                <motion.h1 variants={variants} className="getin">Get In</motion.h1>
                <h1>Touch</h1>
            </motion.div>
            <div className="formContainer">
                <motion.form
                    ref={formRef}
                    onSubmit={sendEmail}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                >
                    <input type="text" required placeholder="Name" name="user_name"/>
                    <input type="email" required placeholder="Email" name="user_email"/>
                    <textarea rows={8} placeholder="Message" name="message"/>
                    <button type="submit">{loading ? <div className="spinner-border text-primary" role="status">
                    </div> : 'Submit'}</button>
                </motion.form>
            </div>
        </motion.div>
    );
};

export default Contact;