import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {Button} from "react-bootstrap";
import {useState} from "react";
import {Sidebar} from "../components/Sidebar";
import avatar from "../assets/avatar.png"

interface HomePageProps {
    isHomeVisible: boolean;
    isSkillsVisible: boolean;
    isPortfolioVisible: boolean;
    isContactVisible: boolean;
}

export const HomePage = (props: HomePageProps) => {
    const [showSidebar,setShowSidebar] = useState(true)

    const visibilityKeys: { [key: string]: keyof typeof props } = {
        Home: 'isHomeVisible',
        Skills: 'isSkillsVisible',
        Portfolio: 'isPortfolioVisible',
        Contact: 'isContactVisible'
    }

    const moveToSection = (id: string) => {
        const element = document.getElementById(id)
        element?.scrollIntoView({
            behavior: 'smooth'
        })
    }
    const arrayButtons = ["Home", "Skills", "Portfolio", "Contact"]

    return (
        <div>
            <header className="header">
                <div className="logo">
                    <a href="#" onClick={(event) => {
                    event.preventDefault()
                    moveToSection('Home')
                }}>Matias</a>
                    <p>.</p>
                </div>
                <nav className="navbar">
                    <Button className="navbarButton" onClick={()=>setShowSidebar(!showSidebar)}>
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                    {arrayButtons.map((section) => (
                        <a key={section} href="#" className={props[visibilityKeys[section]] ? 'active' : ''}
                           onClick={(event) => {
                               event.preventDefault()
                               moveToSection(section)
                           }}>{section}</a>
                    ))}
                </nav>
                <Sidebar arrayButtons={arrayButtons} homeProps={props} moveToSection={moveToSection} show={showSidebar}/>
            </header>

            <div className="home">
                <div className="home-content">
                    <h1>Hi, I'm Matias Norambuena</h1>
                    <h3>FullStack Developer</h3>
                    <p>I'm a FullStack Developer with 4 years of experience in backend/frontend frameworks, AWS
                        technologies, CI/CD tools, and mobile development. I have led projects from concept to
                        deployment, focusing on robust and user-friendly applications. I collaborate with
                        cross-functional teams to develop innovative solutions, optimize performance, and ensure
                        seamless integration. Additionally, I implement automated workflows and containerization for
                        efficient development and deployment. I am eager to
                        deepen my knowledge in native mobile application development. </p>
                    <div className="btn-box">
                        {/*<a href="#">Hire me</a>*/}
                        <a href="#" onClick={(event)=>{event.preventDefault()
                            moveToSection("Contact")}}>Let's Talk</a>
                    </div>
                    <div className="home-sci">
                        <a href="https://github.com/matias-norambuena">
                            <i className="bx bxl-github"></i>
                        </a>
                        <a href="https://www.linkedin.com/in/matias-norambuena-55328197/?locale=en_US">
                            <i className="bx bxl-linkedin"></i>
                        </a>
                    </div>
                </div>
                <div>
                    <img className="profile-picture" src={avatar} alt="profile-picture"/>
                </div>
            </div>
        </div>
    );
};