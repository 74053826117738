import React, { useEffect, useState } from 'react';

const useOnScreen = (
    ref: React.RefObject<HTMLElement>,
    rootMargin: string = "0px",
    threshold: number = 0.2
) => {
    const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

    useEffect(() => {
        if (!ref.current) return;

        const currentElement = ref.current; // Captura el valor actual de ref.current

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry) {
                    setEntry(entry);
                }
            },
            {
                rootMargin,
                threshold
            }
        );

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [ref, rootMargin, threshold]);

    return entry;
};

export default useOnScreen;
