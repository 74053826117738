import {HomePage} from "./HomePage";
import Parallax from "./Parallax/Parallax";
import Skills from "./Skills/Skills";
import Portfolio from "./Portfolio/Portfolio";
import React, {useRef} from "react";
import useOnScreen from "../hooks/UseOnScreen";
import Contact from "./Contact/Contact";

export function App() {
    const homeRef = useRef<HTMLElement>(null);
    const skillsRef = useRef<HTMLElement>(null);
    const portfolioRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLElement>(null);
    const parallaxRef= useRef<HTMLElement>(null);

    const homeEntry = useOnScreen(homeRef);
    const skillsEntry = useOnScreen(skillsRef);
    const portfolioEntry = useOnScreen(portfolioRef);
    const contactEntry = useOnScreen(contactRef);

    const isHomeVisible = homeEntry?.isIntersecting || false;
    const isSkillsVisible = skillsEntry?.isIntersecting || false;
    const isPortfolioVisible = portfolioEntry?.isIntersecting || false;
    const isContactVisible = contactEntry?.isIntersecting || false;

    return (
        <div>
            <section id="Home" className="homepage" ref={homeRef}>
                <HomePage isHomeVisible={isHomeVisible}
                          isContactVisible={isContactVisible}
                          isPortfolioVisible={isPortfolioVisible}
                          isSkillsVisible={isSkillsVisible}/>
            </section>
            <section ref={parallaxRef}><Parallax type={"services"}/></section>
            <section id="Skills" className="skills-section align-content-top" ref={skillsRef}><Skills/></section>
            <section ref={parallaxRef}><Parallax type={""}/></section>
            <div id="Portfolio" ref={portfolioRef}><Portfolio/></div>
            <section id="Contact" ref={contactRef}><Contact/></section>
        </div>
    );
}