import "./skills.css";
import "react-multi-carousel/lib/styles.css";
import reactjs from "../../assets/logos/reactjs.png";
import svelte from "../../assets/logos/svelte.png";
import typescript from "../../assets/logos/typescript.png";
import springboot from "../../assets/logos/springboot.png";
import django from "../../assets/logos/django.png";
import kotlin from "../../assets/logos/kotlin.png";
import python from "../../assets/logos/python.png";
import java from "../../assets/logos/java.png";
import jenkins from "../../assets/logos/jenkins.png";
import docker from "../../assets/logos/docker.png";
import jboss from "../../assets/logos/jboss.png";
import terraform from "../../assets/logos/terraform.png";
import apache from "../../assets/logos/apache.png";
import aws from "../../assets/logos/aws.png";
import mariadb from "../../assets/logos/mariadb.png";
import oracle from "../../assets/logos/oracle.png";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import {useState} from "react";
import CarouselComponent from "../../components/CarouselComponent";

const frontendArray = [
    {src: reactjs, title: 'ReactJS / Native'},
    {src: svelte, title: 'Svelte'},
    {src: typescript, title: 'TypeScript'},
];

const backendArray = [
    {src: springboot, title: 'Spring Boot'},
    {src: django, title: 'Django'},
    {src: kotlin, title: 'Kotlin'},
    {src: python, title: 'Python'},
    {src: java, title: 'Java'},
    {src: mariadb, title: 'MariaDb'},
    {src: oracle, title: 'Oracle'},
];

const cicdArray = [
    {src: jenkins, title: 'Jenkins'},
    {src: docker, title: 'Docker'},
    {src: jboss, title: 'JBoss'},
    {src: terraform, title: 'Terraform'},
    {src: apache, title: 'Apache'},
];

const cloudArray = [
    {src: aws, title: 'AWS'},
];

const Skills = () => {
    const [tabSelected, setTabSelected] = useState("first");

    return (
        <div className="skill" id="skills">
            <Container fluid="xl">
                <div className="row justify-content-md-center p-4">
                    <h2 className="d-flex align-items-center justify-content-center">Skills</h2>
                    <p className="text-center mx-auto w-75">Here is a summary of the technologies I have used throughout my career. As a
                        web and mobile developer, and DevOps engineer specializing in AWS, I have extensive experience
                        with various tools and platforms. My expertise includes application deployment with
                        Infrastructure as Code, automating complex regulatory reporting systems, and developing robust,
                        scalable applications across different industries.</p>
                </div>

                <Row className="container-slider">
                    <Col size={12}>
                        <TrackVisibility>
                            {({isVisible}) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <Tab.Container id="skills-tabs" activeKey={tabSelected} onSelect={(key) => setTabSelected(key || "first")}>
                                        <Nav variant="pills"
                                             className="nav-pills mb-5 justify-content-center align-items-center"
                                             id="pills-tab">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Frontend</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Backend</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">CI/CD</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth">Cloud</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content id="slideInUp"
                                                     className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                            <Tab.Pane eventKey="first">
                                                <CarouselComponent arrayLogos={frontendArray}/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <CarouselComponent arrayLogos={backendArray}/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <CarouselComponent arrayLogos={cicdArray}/>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <CarouselComponent arrayLogos={cloudArray}/>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Skills;
