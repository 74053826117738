import React, {useRef} from "react";
import "./portfolio.css";
import {motion, useScroll, useTransform} from "framer-motion";
import nemesio from '../../assets/portfolio-background/nemesio.png'
import bancoCentral from '../../assets/portfolio-background/banco-central.png'
import swappi from '../../assets/portfolio-background/swappi.png'
import swappiGif from '../../assets/portfolio-background/swappi.gif'
import transbank from '../../assets/portfolio-background/aws-diagram.png'

interface PortfolioProps {
    item: {
        id: string | number,
        img: string,
        item?: string,
        desc: string,
        title: string,
        gif?: string
    }
}


const items = [
    {
        id: 1,
        title: "Nemesio",
        img: nemesio,
        desc: "Platform used by the company \"Bendito Residuo\" for waste management by associated companies. " +
            "On this platform, it is possible to schedule waste pickups, view recycling process statistics, and access" +
            " educational material. It was developed using the Svelte framework for the Frontend and Django RDF for the" +
            " Backend. The deployment process is automated with Jenkins."
    },
    {
        id: 2,
        title: "Swappi",
        img: swappi,
        desc: "Application for condominium management, including common expenses, maintenance, and task delegation." +
            " It was developed using React Native, enabling deployment on both Android and iOS.",
        gif: swappiGif
    },
    {
        id: 3,
        title: "SGD Banco Central",
        img: bancoCentral,
        desc: "Debt Management System application for the Central Bank of Chile. " +
            "This application aims to replace the current, outdated debt management system. It was developed using Java" +
            " and Spring Boot for the backend, and ReactJS for the frontend. Deployment was automated with Jenkins and" +
            " deployed on a JBoss web server.",
    },
    {
        id: 4,
        title: "Transbank",
        img: transbank,
        desc: "At Transbank, I worked as a DevOps Engineer where I was responsible for deploying applications using" +
            " Infrastructure as Code (IaC) in AWS. My role involved automating and streamlining the deployment process" +
            " with tools like Terraform for provisioning, Jenkins for continuous integration and delivery (CI/CD)," +
            " and Artifactory for managing binary artifacts. This approach ensured efficient, scalable, and reliable" +
            " deployments, aligning with industry best practices and organizational goals.",
    }
];

const Single: React.FC<PortfolioProps> = ({item}) => {
    const ref = useRef();

    const {scrollYProgress} = useScroll({
        target: ref,
    });

    const y = useTransform(scrollYProgress, [0, 1], [-50, 50]);

    return (
        <section className={'project' + item.id}>
            <div className="container">
                <div className="wrapper">
                    <div className="imageContainer" ref={ref}>
                        <img src={item.img} alt="" className="img-fluid w-100 h-auto rounded image"/>
                        {item.gif && <img src={item.gif} alt="" className="img-fluid w-100 h-auto rounded gif"/>}
                    </div>
                    <motion.div className="textContainer" style={{y}}>
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

const Portfolio = () => {
    /*    const ref = useRef();

        const {scrollYProgress} = useScroll({
            target: ref,
            offset: ["end end", "start start"],
        });

        const scaleX = useSpring(scrollYProgress, {
            stiffness: 100,
            damping: 30,
        });*/

    return (
        <div className="portfolio">
            <div className="portfolio-title">
                <motion.h2 initial={{opacity: 0}}
                           whileInView={{opacity: 1}}
                           exit={{opacity: 0}}
                           transition={{duration: 3}}
                >Featured Works
                </motion.h2>
            </div>
            {items.map((item) => (
                <Single item={item} key={item.id}/>
            ))}
        </div>
    );
};

export default Portfolio;